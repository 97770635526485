.contact {
  margin: 20px 5px;
  text-align: center;
  font-size: larger;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    max-width: 800px;

    input {
      border: 2px solid rgb(229, 229, 229);
      outline: none;
      border-radius: 2px;
      width: 85%;
      margin-top: 12px;
      height: 30px;
      transition: 220ms all ease-in-out;
    }

    textarea {
      border: 2px solid rgb(229, 229, 229);
      outline: none;
      border-radius: 2px;
      margin: 12px 0;
      width: 85%;
      height: 20vh;
      resize: none;
      transition: 220ms all ease-in-out;
    }

    textarea:focus,
    input:focus {
      border: 2px solid #11abb0;
    }
  }
  .success {
    background-color: rgba(0, 128, 0, 0.374);
    margin: 5px;
    border: 2px solid darkgreen;
    color: green;
    p {
      margin: 5px;
    }
  }

  .error {
    background-color: rgba(255, 0, 0, 0.288);
    margin: 5px;
    border: 2px solid darkred;
    color: red;
    p {
      margin: 5px;
    }
  }
}
