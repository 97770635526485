#about {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgb(70, 70, 70), rgba(70, 70, 70, 0.5)),
    url(/images/dark-landscape.png) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  overflow: hidden;

  .container {
    display: flex;
    width: 85%;
    max-width: 1200px;
    align-items: center;
    justify-content: center;

    .headshot-container {
      width: 35%;

      .profile-pic {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 60px;
          border-radius: 10px;
          object-fit: cover;
          max-width: 500px;
        }
      }
    }

    .information-container {
      padding: 20px 30px;
      background-color: rgb(25, 25, 25);
      width: 100%;
      border: 5px solid #0b797d68;
      border-radius: 4px;

      p {
        font-size: large;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #about {
    padding: 30px;

    .container {
      flex-direction: column;
      width: 90%;

      .profile-pic {
        img {
          border-radius: 0 !important;
          max-width: 85vw !important;
          max-height: 50vh;
          margin-left: 0px !important;
        }
      }

      .information-container {
        width: 104%;
        margin-top: 12px;
        border: none;
        padding: 20px 20px;
        text-align: center;
      }
    }
  }
}
