@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Spinnaker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

html {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/images/dark-beach.png) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;

  font-family: 'Montserrat', sans-serif;
  color: rgb(218, 218, 218);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input {
  font-family: 'Montserrat', sans-serif;
}

textarea {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 7vw;
  margin: 0;
}

button {
  cursor: pointer;
  font-size: 20px;
  background-color: #11abb0d4;
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 10px;
  transition: 220ms all ease-in-out;

  &:hover {
    color: #edf9fad4;
    background-color: scale-color($color: #11abb0d4, $lightness: -20%);
  }
}

.logo {
  font-size: x-large;
  font-family: 'Monoton';

  a {
    &:hover,
    &:focus {
      color: #e7e7e7;
    }
  }
}

a {
  cursor: pointer;
  color: rgb(218, 218, 218);
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:visited {
    color: #11abb0;
  }
  &:hover,
  &:focus {
    color: #11abb0;
  }
}

@media only screen and (max-width: 531px) {
  p {
    font-size: 16px;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
//  bounce animation
.swing {
  transition: all 0.3s ease;
}

@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
