.project-item-wrapper {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .project-item {
    text-align: center;
    max-width: 75%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 550px;
    align-items: center;

    &:hover {
      & .image {
        transform: scale(1.2);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
    }

    .image {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 3%;
      width: 25vw;
      min-height: 500px;
      max-height: 500px;
      background-size: cover;
      background-position: center;
      margin-top: 60px;
    }

    .card-top {
      padding: 10px 25px;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.883) !important;
      backdrop-filter: blur(5px) !important;
      border-radius: 5px;
    }

    .name {
      color: rgb(26, 26, 26);
    }

    .bottom {
      min-width: 360px;
      background-color: rgba(255, 255, 255, 0.802);
      backdrop-filter: blur(5px) !important;
      border-radius: 5px;
      border-top: 3px solid rgba(128, 128, 128, 0.394);
      padding: 10px 0;
      position: relative;
      bottom: 70px;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      width: 100%;

      .link-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .description {
      font-size: 18px;
      background-color: rgba(255, 255, 255, 0.15) !important;
      backdrop-filter: blur(20px) !important;
      border-radius: 10px;
      padding: 15px;
      width: 65%;
      color: rgb(46, 46, 46);
      margin: 0 auto;
    }
  }

  .techs-wrapper {
    position: relative;
    margin-top: 20px;
    .techs {
      img {
        padding: 0px 2.5px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .project-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;

    .image {
      margin-top: 20px !important;
      min-width: 200px !important;
      width: 100% !important;
      height: 60vh !important;
    }

    .collection-footer {
      opacity: 1 !important;
    }
    .card-top {
      padding: 0 !important;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 531px) {
  .project-item-wrapper {
    .project-item {
      &:hover {
        & .image {
          transform: scale(1);
        }
      }
    }

    .name {
      padding: 0 12px;
    }

    .image {
      margin-top: 60px !important;
      width: 85vw !important;
      height: 66vh !important;
    }

    .collection-footer {
      opacity: 1 !important;
    }

    .techs-wrapper {
      width: 100vw;
      margin-top: -16px;
    }

    .bottom {
      min-width: 85vw !important;
    }
  }
}
