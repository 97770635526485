.projects-container {
  display: flex;
  padding-top: 80px;
  text-align: center;
  overflow: hidden;

  .projects {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 531px) {
  .projects {
    flex-direction: column;
    align-items: center;
  }
}
