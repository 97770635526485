#resume {
  color: #333333;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 85vw;
  background: rgb(233, 233, 233);
  padding: 70px 0px;
  border: 4px solid #0b7a7d;
  border-radius: 10px;
  overflow: hidden;

  a {
    color: #11abb0;
  }

  li {
    width: 85%;
  }
  .resume-section {
    width: 90vw;
    display: flex;
    justify-content: center;
  }

  .header-column {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    width: 40%;
  }

  .content-column {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;

    span {
      color: #333333;
      border-bottom: 3px solid #11abb0;
      padding-bottom: 6px;
    }
  }

  h3 {
    font: 25px/30px 'opensans-bold', sans-serif;
  }

  p {
    margin-right: 5%;
  }

  // Work Section
  .work {
    margin-top: 60px;
    .resume-bullet-points {
      list-style: none;
      padding: 0 20px;

      li {
        line-height: 24px;
        font-size: 16px;
      }

      li::before {
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #10aaaf; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        font-size: 24px;
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
    .date {
      margin-top: 6px;
    }

    .info {
      color: #6e7881;
      margin-bottom: 18px;
      margin-top: 9px;

      span {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  // Skills section
  .skills {
    margin-top: 60px;
    .bars {
      padding: 18px 0px;
      width: 95%;
      float: left;

      text-align: left;

      li {
        position: relative;
        margin-bottom: 60px;
        background: #ccc;
        height: 42px;
        border-radius: 3px;

        .bar-expand {
          position: absolute;
          left: 0;
          top: 0;
          margin: 0;
          padding-right: 24px;
          background: #287f82;
          display: inline-block;
          height: 42px;
          line-height: 42px;
          border-radius: 3px 0 0 3px;
        }

        em {
          font: 15px 'opensans-bold', sans-serif;
          color: #313131;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: normal;
          position: relative;
          top: -36px;
        }
      }

      .skills-list {
        list-style: none;
        margin-top: 40px;
        padding: 12px 20px;
      }

      .css {
        width: 90%;
      }
      .html5 {
        width: 80%;
      }
      .javascript {
        width: 70%;
      }
      .reactjs {
        width: 90%;
      }
      .json {
        width: 67%;
      }
      .git {
        width: 56%;
      }
      .ruby {
        width: 60%;
      }
      .ruby-on-rails {
        width: 60%;
      }
      .sql {
        width: 50%;
      }
      .firebase {
        width: 50%;
      }
      .jwt-authentication {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 531px) {
  #resume {
    padding: 45px 0px;
    .resume-section {
      width: 100vw;
      flex-direction: column;
    }

    .header-column {
      width: 100%;
      margin: 0 auto;
    }

    .content-column {
      width: 100%;
      padding: 0 10px;

      .skills {
        padding: 0;
        margin-top: 30px;

        .header-column {
          padding-bottom: 30px;
        }
        .bars {
          margin: 0 auto;
        }
      }
    }

    p {
      width: 85%;
    }
    em {
      width: 85%;
    }
    .skills-list {
      padding: 12px 20px;
    }

    .resume-bullet-points {
      padding: 0 32px;
      li {
        line-height: 24px;
      }
    }

    .work {
      margin-top: 30px;
    }
  }
}
